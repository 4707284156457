<template>
  <div>
    <MyTable
      :columns="columns"
      :rows="rows"
      :with-pagination="false"
      :with-search="false"
      :with-select-option="true"
      :is-loading="isLoading"
      @selectedRows="handleSelectedRows"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'id'">
          <b-button
            variant="primary"
            class="w-100"
            :to="({ name: 'deliverysummary', params: { id: props.row.id } })"
          >{{ props.row.reference_id }}</b-button>
        </span>

        <span v-else-if="props.column.field === 'shipment_type'">
          <b-badge
            :variant="props.row.status.color_name"
            class="p-1 text-white font-weight-bold w-100"
            :class="props.row.shipment_type === 'Smarttruck' ? 'text-uppercase bg-primary ' : 'bg-success'"
          >
            {{ props.row.shipment_type }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'sender.complete'">
          {{ props.row.sender.complete }}
        </span>

        <span v-else-if="props.column.field === 'recipient.complete'">
          {{ props.row.recipient.complete }}
        </span>

        <span v-else-if="props.column.field === 'delivery_date'">
          {{ $helpers.formatDateTime(props.row.delivery_date) }}
        </span>

        <!-- <span v-else-if="props.column.field === 'unit_price'">
          {{ $helpers.formatTotal(props.row.unit_price) }}
        </span> -->

        <span v-else-if="props.column.field === 'pickup_charge'">
          {{ $helpers.formatTotal(props.row.pickup_charge) }}
        </span>

        <span v-else-if="props.column.field === 'tax_charge_percentage'">
          {{ props.row.tax_charge_percentage }}
        </span>

        <span v-else-if="props.column.field === 'price'">
          {{ $helpers.formatTotal(props.row.price) }}
        </span>
        <span v-else-if="props.column.field === 'action'">
          <b-button
            size="sm"
            variant="primary"
            @click.prevent="removeCartItem(props.row.id)"
          >x  </b-button>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>
    <MyCharges
      :charges="overAllCharges"
      :order-count="selectedRows.length"
      :process-payment="processPayment"
      @applyVoucher="handleApplyVoucher"
      @proceedToPayMent="handleProceedToPayment"
    />
  </div>
</template>

<script>
import MyCharges from '@/components/MyCharges.vue'
import MyTable from '@/components/MyTable.vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'MyCart',
  components: {
    MyTable,
    MyCharges,
  },
  data() {
    return {
      columns: [
        {
          label: 'Order ID',
          field: 'id',
          thClass: 'align-middle text-left',
          tdClass: 'align-middle text-left',
          type: 'number',
          filterOptions: {
            customFilter: true,
          },
        },
        {
          label: 'Shipment',
          field: 'shipment_type',
          thClass: 'align-middle',
          tdClass: 'align-middle',
          html: true,
        },
        {
          label: 'Pick Up Address',
          field: 'sender.complete',
          thClass: 'align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Recipient Address',
          field: 'recipient.complete',
          thClass: 'align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Delivery Date',
          field: 'delivery_date',
          thClass: 'align-middle text-nowrap',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Quantity(Box)',
          field: 'quantity',
          thClass: 'align-middle text-left',
          tdClass: 'align-middle text-left',
        },
        // {
        //   label: 'Unit Price',
        //   field: 'unit_price',
        //   thClass: 'align-middle text-nowrap text-left',
        //   tdClass: 'align-middle text-left',
        //   type: 'decimal',
        // },
        {
          label: 'Pick Up Charge',
          field: 'pickup_charge',
          thClass: 'align-middle text-nowrap text-left',
          tdClass: 'align-middle text-left',
          type: 'decimal',
        },
        {
          label: 'Tax',
          field: 'tax_charge_percentage',
          thClass: 'align-middle text-nowrap text-left',
          tdClass: 'align-middle text-left',
          type: 'percentage',
        },
        {
          label: 'Total',
          field: 'price',
          thClass: 'align-middle text-nowrap text-left',
          tdClass: 'align-middle text-left',
          type: 'decimal',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'align-middle text-nowrap text-left',
          tdClass: 'align-middle text-left',
        },
      ],
      rows: [],
      selectedRows: [],
      charges: {},
      isLoading: false,
      voucherAmount: 0,
      voucherType: 'per_order',
      voucher: null,
      processPayment: false,
      gologCut: 0,
    }
  },
  computed: {
    overAllCharges() {
      return this.selectedRows.reduce((previous, current) => ({
        subTotal: previous.subTotal + current.price,
        totalDiscount: this.totalDiscount,
        pickupCharge: previous.pickupCharge + current.pickup_charge,
        taxCharge: previous.taxCharge + current.tax_charge,
        grandTotal: ((previous.subTotal + current.price) + (previous.pickupCharge + current.pickup_charge) + (previous.taxCharge + current.tax_charge) - this.totalDiscount),
      }), {
        subTotal: 0, totalDiscount: 0, pickupCharge: 0, taxCharge: 0, grandTotal: 0,
      })
    },
    totalQuantity() {
      return this.selectedRows.reduce((previous, current) => previous + current.quantity, 0)
    },
    totalDiscount() {
      return this.voucherType == 'per_box' ? (this.selectedRows.reduce((previous, current) => previous + current.quantity, 0) * this.voucherAmount) : this.voucherAmount
    },
  },
  created() {
    this.fetchGologCut()
    this.fetchCartItems()
  },
  methods: {
    async removeCartItem(id) {
      this.$swal({
        title: `Confirm remove ${id} from cart`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'No',
        cancelButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-danger mr-1',
          cancelButton: 'btn btn-success',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed == false) {
          this.isLoading = true
          const response = await axios.delete(`/cart-items/remove/${id}`)
          if (response.status === 200) {
            this.fetchCartItems()
          }
          this.isLoading = false
        }
      })
    },
    async fetchCartItems() {
      this.isLoading = true
      const response = await axios.get('/cart-items')
      if (response.status === 200) {
        this.rows = response.data
      }
      this.isLoading = false
    },
    async fetchGologCut() {
      const response = await axios.get('/prices/golog-cut')
      if (response.status === 200) {
        this.gologCut = response.data
      }
    },
    async handleProceedToPayment(mop) {
      // alert(mop)
      this.processPayment = true
      const orders = this.selectedRows.map(order => ({
        id: order.id,
        price: order.price,
        smarttruck_cut: this.calculateSmattruckCut(order),
        golog_cut: (order.quantity * this.gologCut),
      }))
      orders.forEach(async order => {
        await this.$http.post('order/update_payment_method', {
          order_id: order.id,
          payment_method: mop,
        })
      })
      if (mop === 'SENANGPAY') {
        const form = {
          orders,
          total_charge: this.overAllCharges.grandTotal.toFixed(2),
          voucher: this.voucher,
        }
        const response = await axios.post('place-delivery', form)
        if (response.status === 200) {
          this.processPayment = false
          window.location.href = response.data.url
        }
      } else if (mop === 'LOGPOINT') {
        let link = ''
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          const processLogpoint = await this.$http.post(
            'logpoint/process_order',
            { order_id: order.id, total_amount: order.price },
          )
          if (processLogpoint.data.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  `${order.id} successfully paid with Logpoints`,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            console.log(processLogpoint)
            link = `/delivery-confirmed/${processLogpoint.data.data.reference_number}/${mop}`
            // window.location.href = `/delivery-confirmed/${processLogpoint.data.data.reference_number}/${this.mop}`
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Not Enough Logpoint. Please reload or choose other payment methods.',
                icon: 'WarningIcon',
                variant: 'warning',
              },
            })
          }
        }
        this.processPayment = false
        console.log(link)
        if (link != '') { window.location.href = link }
      }
    },
    handleSelectedRows(data) {
      this.selectedRows = data.selectedRows
    },
    async handleApplyVoucher(voucher) {
      const response = await axios.get(`/prices/voucher-discount/${voucher}`)
      if (response.status === 200) {
        this.voucherAmount = response.data.discount
        this.voucherType = response.data.type
        this.voucher = voucher
      }
    },
    calculateFinalPrice(order) {
      let finalPrice = order.price + order.pickup_charge + order.tax_charge
      if (this.voucher) {
        finalPrice -= ((this.voucherType === 'per_box') ? (order.quantity * this.voucherAmount) : this.voucherAmount)
      }
      return finalPrice
    },
    calculateSmattruckCut(order) {
      return this.voucher ? (order.price - ((this.voucherType === 'per_box') ? (order.quantity * this.voucherAmount) : this.voucherAmount)) - (order.quantity * this.gologCut) : order.price - (order.quantity * this.gologCut)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
